import React from 'react'
import { Collapse } from 'antd'
import classes from './CollapseComponent.module.css'
import Icons from '../Icons/Icons'
import { blue1 } from '../../utils/consts'

const CollapseComponent = ({items, label, text, accordion = false, defaultActiveKey, activeKey, setActiveKey, headerBackground, isWindowWidthTablet, isWindowWidthMobile, expandIcon, rotateFrom, rotateTo, className, iconContainerClassName, hasPseudos}) => {
  items=items
  ?
    items
  : 
    [
      {
        key: '1',
        label: label,
        children: <div>{text}</div>
      },
    ]
  const panelStyle = {
    marginBottom: isWindowWidthMobile ? 0 : 44,
  }
  return (
    <div className={`${classes['collapse-container']} ${className}`}>
      <div className={`${classes['collapse-background']}`}>{!isWindowWidthTablet && headerBackground}</div>
      <Collapse
        defaultActiveKey={defaultActiveKey ? defaultActiveKey : null}
        accordion={accordion}
        items={items}
        activeKey={activeKey}
        onChange={(e) => setActiveKey ? setActiveKey(e[0]) : null}
        expandIcon={({isActive}) =>
          <div className={`${classes['collapse-iconContainer']} ${iconContainerClassName}`}>
            {hasPseudos &&
              <>
                <div className={classes['collapse-iconContainer-blockForPseudosAfter']}></div>
                <div className={classes['collapse-iconContainer-blockForPseudosBefore']}></div>
              </>
            }
            {expandIcon
            ?
              expandIcon
            :
              <Icons
                style={{rotate: isActive ? rotateFrom || '0deg' : rotateTo || '-45deg'}}
                name='x'
                size='24px'
                className={classes['collapse-icon']}
              />
            }
          </div>
          
        }
        expandIconPosition='end'
        bordered={false}
        ghost
        style={panelStyle}
        background={headerBackground}
      />
    </div>
  )
}

export default CollapseComponent