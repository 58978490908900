import { $authHost, $host } from './index'

export const getOrdersList = async (limit, offset, orderNumber = null, lastName = null, orderBy = 'createdAt', orderByDirection = 'desc') => {
  const {data} = await $authHost.post(`v1/admin/orders/list?offset=${offset}&limit=${limit}`, {orderNumber, lastName, orderBy, orderByDirection});
  return data;
}

export const getOrder = async (id) => {
  const {data} = await $authHost.get(`v1/admin/orders/${id}`);
  return data;
}

export const updateOrder = async (id, email, phoneNumber, status) => {
  const {data} = await $authHost.put(`v1/admin/orders/${id}`, {email, phoneNumber, status});
  return data;
}

export const getUsersList = async (limit, offset, orderBy = 'createdAt', orderByDirection = 'desc') => {
  const {data} = await $authHost.post(`v1/admin/users/list?offset=${offset}&limit=${limit}`, {orderBy, orderByDirection});
  return data;
}

export const getTripsList = async (limit, offset, orderBy = 'createdAt', orderByDirection = 'desc') => {
  const {data} = await $authHost.post(`v1/admin/trips/list?offset=${offset}&limit=${limit}`, {orderBy, orderByDirection});
  return data;
}

export const getTrip = async (id) => {
  const {data} = await $authHost.get(`v1/admin/trips/${id}`);
  return data;
}

export const enableTrip = async (id, enabled) => {
  const {data} = await $authHost.post(`v1/admin/trips/${id}/enable`, {enabled});
  return data;
}

export const cancelRequestTrip = async (id) => {
  const {data} = await $authHost.post(`v1/admin/trips/${id}/cancel-request`);
  return data;
}

export const confirmCancelTrip = async (tripId, code) => {
  const {data} = await $authHost.post(`v1/admin/trips/cancel`, {tripId, code});
  return data;
}

export const getCancelTripStatus = async (id) => {
  const {data} = await $authHost.get(`v1/admin/trips/cancel/${id}`);
  return data;
}

export const getSettingsList = async (limit, offset, name, orderBy = 'createdAt', orderByDirection = 'desc') => {
  const {data} = await $authHost.post(`v1/admin/settings/list?offset=${offset}&limit=${limit}`, {name, orderBy, orderByDirection});
  return data;
}

export const changeSetting = async (id, value) => {
  const {data} = await $authHost.post(`v1/admin/settings/${id}`, {value});
  return data;
}