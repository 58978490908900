import { createSlice } from '@reduxjs/toolkit';
 
const UserStore = createSlice({
  name: "user",
  initialState: {
    _user: {
      type: 'default',
      userInfo: {},
    },
    _isAuth: null,
  },
  reducers: {
    setUserType(state, action) {
      state._user.type = action.payload;
      console.log(state._user)
    },
    setUserInfo(state, action) {
      state._user.userInfo = action.payload;
      console.log(state._user.userInfo)
    },
    setIsAuth(state, action) {
      state._isAuth = action.payload;
      console.log(state._isAuth)
    },
  }
})

export const { setUserType, setUserInfo, setIsAuth } = UserStore.actions;

export default UserStore.reducer;