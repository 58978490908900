import React from 'react';
import classes from './NavBar.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { AUTH_ROUTE, PROFILE_ROUTE, SETTINGS_ROUTE, TICKETS_ROUTE, TRIPS_ROUTE, USERS_ROUTE } from '../../utils/consts';
import { Button } from '../Button/Button'
import { logout } from '../../http/user';
import { useSelector } from 'react-redux';

const NavBar = () => {
  const userInfo = useSelector(state => state.user._user.userInfo)

  const navigate = useNavigate()

  const fetchLogout = async () => {
    try {
      let {data} = await logout()
      console.log(data)
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      navigate({
        pathname: AUTH_ROUTE,
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <nav className={classes['bar']}>
      <div className={classes['content']}>
        <div className={classes['menu']}>
          <div className={classes['links']}>
            <NavLink to={SETTINGS_ROUTE} className={classes['link']}>Настройки</NavLink>
            <NavLink to={TRIPS_ROUTE} className={classes['link']}>Рейсы</NavLink>
            <NavLink to={TICKETS_ROUTE} className={classes['link']}>Заказы</NavLink>
            <NavLink to={USERS_ROUTE} className={classes['link']}>Пользователи</NavLink>
          </div>
        </div>
        <div className={classes['menu']}>
          <NavLink to={PROFILE_ROUTE} className={classes['profile-link']}>{userInfo.email}</NavLink>
          <Button onClick={() => fetchLogout()}>
            Выход
          </Button>
        </div>
      </div>
    </nav>
  )
}

export default NavBar;