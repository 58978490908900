import { $host } from ".";

export const getAllDictionaries = async () => {
  const {data} = await $host.get('v1/dictionaries/all');
  return data;
}

export const getCountries = async () => {
  const {data} = await $host.get('v1/dictionaries/countries')
  return data;
}