import React from 'react'
import classes from './Button.module.css'
import { beige4 } from '../../utils/consts'

export const Button = ({className, type = 'primary', disabled, buttonRef, loading = false, ...props}) => {
  let primary = classes['button__primary']
  let outlined = classes['button__outlined']
  let primaryLoading = classes['button__primary__loading']
  let outlinedLoading = classes['button__outlined__loading']
  let buttonType = type === 'outlined' ? outlined : type === 'primary' ? primary : primary
  let buttonLoading = loading ? type === 'outlined' ? outlinedLoading : primaryLoading : ''
  const classNames = className ? `${classes.button} ${className} ${buttonType} ${buttonLoading}` : `${classes.button} ${buttonType} ${buttonLoading}`
  return (
    <button className={classNames} disabled={disabled || loading} ref={buttonRef} {...props}>
      {loading &&
        <div className={`${classes['ring-outer']}`}>
          <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={beige4}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="2">
                  <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"/>
                  </path>
                </g>
            </g>
          </svg>
        </div>
      }
      {props.children}
    </button>
  )
}