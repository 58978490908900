import React, { useEffect, useRef, useState } from 'react'
import classes from './Trip.module.css'
import NavBar from '../../components/NavBar/NavBar'
import Wrapper from '../../components/Wrapper/Wrapper'
import { cancelRequestTrip, confirmCancelTrip, enableTrip, getCancelTripStatus, getTrip } from '../../http/admin'
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import H1 from '../../components/H1/H1'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../components/Button/Button'
import { DatePicker, Input, notification, Select, TimePicker } from 'antd'
import { setCancelTripStatus, setTrip } from '../../store/TripStore'
import CollapseComponent from '../../components/Collapse/CollapseComponent'
import { setStorageWithExpiry } from '../../utils/setStorageWithExpiry'
import { getStorageWithExpiry } from '../../utils/getStorageWithExpiry'
import { beige4, blue1, TRIP_ROUTE } from '../../utils/consts'
import Icons from '../../components/Icons/Icons'

const Trip = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  let id = params.get('id')
  let cancelId = params.get('cancelId')
  const tripData = useSelector(state => state.trip._trip)
  const [isEditing, setIsEditing] = useState(false)
  const allDictionaries = useSelector(state => state.dictionary._dictionaries)
  const tripStatuses = allDictionaries.tripStatuses
  const [loadings, setLoadings] = useState([])
  const [cancelTripConfirmCode, setCancelTripConfirmCode] = useState('')
  const [isCancelTripConfirmCodeSended, setIsCancelTripConfirmCodeSended] = useState(true)
  const cancelTripStatus = useSelector(state => state.trip._cancelTripStatus)
  const [sendCancelTripConfirmCodeButtonIsLoading, setSendCancelTripConfirmCodeButtonIsLoading] = useState(false)
  const [confirmCancelTripButtonIsLoading, setConfirmCancelTripButtonIsLoading] = useState(false)
  const [getCancelTripStatusButtonIsLoading, setGetCancelTripStatusButtonIsLoading] = useState(false)
  const [updateCancelTripStatusIsRunning, setUpdateCancelTripStatusIsRunning] = useState(false)
  const updateCancelTripStatusInterval = useRef(null)

  const [messageApi, contextHolder] = notification.useNotification()
  const returnMessage = (type, title, content, className, style, duration) => {
    messageApi.open({
      // type: type,
      message: title,
      description: content,
      className: 'notification',
      style: style,
      duration: duration,
      placement: 'top',
      closeIcon:
        <Icons
          name='x'
          size='24px'
          stroke={blue1}
        />,
    })
  }

  const fetchTrip = async (id) => {
    try {
      let {data} = await getTrip(id)
      console.log(data)
      dispatch(setTrip(data))
    } catch (e) {
      console.log(e)
    }
  }

  const fetchEnableTrip = async (id, enabled, index) => {
    try {
      let {data} = await enableTrip(id, enabled)
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = false
        return newLoadings
      })
      dispatch(setTrip(data))
    } catch (e) {
      try {
        returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
      } catch (er) {
        console.log(e)
      }
    }
  }

  const fetchCancelTrip = async () => {
    setSendCancelTripConfirmCodeButtonIsLoading(true)
    try {
      let response = await cancelRequestTrip(id)
      returnMessage('', '', response.message, '', '', 10)
      setStorageWithExpiry(`isCancelTripConfirmCodeActiveId=${id}`, true, dayjs().add(10, 'minutes').format('YYYY-MM-DDTHH:mm:ss'))
      setIsCancelTripConfirmCodeSended(getStorageWithExpiry(`isCancelTripConfirmCodeActiveId=${id}`))
    } catch (e) {
      try {
        returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
      } catch (er) {
        console.log(e)
      }
    } finally {
      setSendCancelTripConfirmCodeButtonIsLoading(false)
    }
  }

  const fetchConfirmCancelTrip = async () => {
    if (cancelTripConfirmCode.replace(/\s/g, '') === '') {
      returnMessage('error', '', 'Введите код подтверждения', '', '', 5)
    }
    else {
      setConfirmCancelTripButtonIsLoading(true)
      try {
        let {data} = await confirmCancelTrip(id, cancelTripConfirmCode)
        dispatch(setCancelTripStatus(data))
        setStorageWithExpiry(`isCancelTripConfirmCodeActiveId=${id}`, true, dayjs().add(-1, 'days').format('YYYY-MM-DDTHH:mm:ss'))
        let tripsCancellingList = []
        if (getStorageWithExpiry('tripsCancellingList')) {
          tripsCancellingList = JSON.parse(getStorageWithExpiry('tripsCancellingList'))
          tripsCancellingList = tripsCancellingList.filter((item) => item.tripId !== id)
        }
        tripsCancellingList.push({
          tripId: id,
          cancelId: data.id,
        })
        setStorageWithExpiry('tripsCancellingList', JSON.stringify(tripsCancellingList), dayjs().add(1, 'days').format('YYYY-MM-DDTHH:mm:ss'))
        setIsCancelTripConfirmCodeSended(getStorageWithExpiry(`isCancelTripConfirmCodeActiveId=${id}`))
        navigate({
          pathname: TRIP_ROUTE,
          search: `?${createSearchParams({
            id: id,
            cancelId: data.id,
          })}`
        })
        if (!updateCancelTripStatusIsRunning) {
          setUpdateCancelTripStatusIsRunning(true)
          updateCancelTripStatusInterval.current = setInterval(() => {
            fetchCancelTripStatus(data, data.id)
          }, 20000)
        }
      } catch (e) {
        try {
          returnMessage('error', '', e.response.data.message, '', '', 10)
        } catch (er) {
          console.log(e)
        }
      } finally {
        setConfirmCancelTripButtonIsLoading(false)
      }
    }
  }

  const fetchCancelTripStatus = async (cancelTripStatus, firstFetchCancelId = null) => {
    console.log('fetching')
    if (cancelTripStatus && cancelTripStatus === 'finished') {
      fetchTrip(id)
      clearInterval(updateCancelTripStatusInterval.current)
    }
    else {
      try {
        setGetCancelTripStatusButtonIsLoading(true)
        let {data} = await getCancelTripStatus(firstFetchCancelId ? firstFetchCancelId : cancelTripStatus ? cancelTripStatus.id : cancelId)
        dispatch(setCancelTripStatus(data))
        navigate({
          pathname: TRIP_ROUTE,
          search: `?${createSearchParams({
            id: id,
            cancelId: data.id,
          })}`
        })
        if (data.status === 'finished') {
          let tripsCancellingList = JSON.parse(getStorageWithExpiry('tripsCancellingList')).filter((item) => item.tripId !== id)
          setStorageWithExpiry('tripsCancellingList', JSON.stringify(tripsCancellingList), dayjs().add(-1, 'days').format('YYYY-MM-DDTHH:mm:ss'))
          setUpdateCancelTripStatusIsRunning(false)
          fetchTrip(id)
          clearInterval(updateCancelTripStatusInterval.current)
        }
      } catch (e) {
        try {
          clearInterval(updateCancelTripStatusInterval.current)
          returnMessage('error', '', e.response.data.message, '', '', 10)
        } catch (er) {
          console.log(e)
        }
      } finally {
        setGetCancelTripStatusButtonIsLoading(false)
      }
    }
  }

  const automaticallyUpdateCancelTripStatus = async () => {
    if (!updateCancelTripStatusIsRunning) {
      setUpdateCancelTripStatusIsRunning(true)
      if (getStorageWithExpiry('tripsCancellingList')) {
        let tripsCancellingList = JSON.parse(getStorageWithExpiry('tripsCancellingList'))
        let currentTripCancellingData = tripsCancellingList.filter((item) => item.tripId === id)
        if (currentTripCancellingData[0]) {
          fetchCancelTripStatus()
          updateCancelTripStatusInterval.current = setInterval(() => {
            fetchCancelTripStatus(cancelTripStatus)
          }, 20000)
        }
        else {
          console.log('This trip is not cancelling')
          setUpdateCancelTripStatusIsRunning(false)
        }
      }
      else {
        console.log('No trips is cancelling')
        setUpdateCancelTripStatusIsRunning(false)
      }
    }
  }

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = false
        return newLoadings
      })
    }, 10000)
  }

  useEffect(() => {
    if (!Object.keys(tripData).length) {
      fetchTrip(id)
    }
    setIsCancelTripConfirmCodeSended(getStorageWithExpiry(`isCancelTripConfirmCodeActiveId=${id}`))
    if (getStorageWithExpiry('tripsCancellingList')) {
      let currentTripCancellingData = JSON.parse(getStorageWithExpiry('tripsCancellingList')).filter((item) => item.tripId === id)
      if (currentTripCancellingData[0]) {
        navigate({
          pathname: TRIP_ROUTE,
          search: `?${createSearchParams({
            id: id,
            cancelId: currentTripCancellingData[0].cancelId,
          })}`
        })
      }
    }
    return () => clearInterval(updateCancelTripStatusInterval.current)
  }, [])

  useEffect(() => {
    if (cancelId) {
      automaticallyUpdateCancelTripStatus()
    }
  }, [cancelId])  

  return (
    <>
      {contextHolder}
      <NavBar/>
      <Wrapper>
        <div className={'previousPage'}>
          <div className={'previousPage-container'} onClick={() => navigate(-1)}>
            <Button
              className={`previousPage-button`}
            >
              <Icons
                name='arrow-left'
                size='24px'
                stroke={beige4}
                className={''} 
              />
            </Button>
            <div className={'previousPage-label'}>Назад</div>
          </div>
        </div>
        <H1></H1>
        {Object.keys(tripData).length > 0 &&
          <div className={classes['trip']}>
            <div className={classes['trip-data']}>
              <div className={`${classes['trip-data-item']}`}>
                <div className={`${classes['trip-data-item-title']}`}>Направление: </div>
                <div className={`${classes['trip-data-item-value']}`}>{tripData.tripTemplate.name}</div>
              </div>
              <div className={`${classes['trip-data-item']}`}>
                <div className={`${classes['trip-data-item-title']}`}>Дата и время: </div>
                <div className={`${classes['trip-data-item-value']}`}>{dayjs(tripData.dateTime).format('DD.MM.YYYY HH:mm:ss')}</div>
              </div>
              <div className={`${classes['trip-data-item']} ${classes['trip-data-item__status']}`}>
                <div className={`${classes['trip-data-item-title']}`}>Статус: </div>
                <div className={`${classes['trip-data-item-value']}`}>{tripStatuses ? tripStatuses[`${tripData.status}`] : '-'}</div>
              </div>
              <div className={`${classes['trip-data-item']}`}>
                <div className={`${classes['trip-data-item-title']}`}>Создан: </div>
                <div className={`${classes['trip-data-item-value']}`}>{dayjs(tripData.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
              </div>
              <div className={`${classes['trip-data-item']}`}>
                <div className={`${classes['trip-data-item-title']}`}>Обновлён: </div>
                <div className={`${classes['trip-data-item-value']}`}>{dayjs(tripData.updatedAt).format('DD.MM.YYYY HH:mm:ss')}</div>
              </div>
              <div className={`${classes['trip-data-item']}`}>
                <div className={`${classes['trip-data-item-value']} ${classes['trip-data-item-value__actions']}`}>
                  <span>
                    <Button disabled={Boolean(isEditing)} className={`${classes['trip-data-editButton']}`} onClick={() => setIsEditing(true)}>Редактировать</Button>
                  </span>
                  <Button
                    className={`${classes['trip-data-editButton']}`}
                    loading={loadings[0]}
                    onClick={() => {
                      fetchEnableTrip(id, (tripData.status === 'disabled' || tripData.status === 'boarding'), 0)
                      enterLoading(0)
                    }}
                  >
                    {(tripData.status === 'disabled' || tripData.status === 'boarding') ? 'Включить' : 'Отключить'}
                  </Button>
                </div>
              </div>
            </div>
            {isEditing &&
              <div className={classes['trip-edit']}>
                <div className={classes['trip-edit-params']}>
                  <DatePicker
                    id='datePickerId'
                    className='datePicker'
                    popupClassName='datePicker-popup'
                    minDate={dayjs()}
                    maxDate={dayjs().add(1, "years")}
                    format={{
                      format: 'DD.MM.YYYY',
                      // type: 'mask',
                    }}
                    defaultValue={dayjs(tripData.dateTime)}
                    placement='topRight'
                    placeholder='Дата рождения'
                  />
                  <TimePicker
                    className={`timePicker ${classes['settings-changeArrivalDepartureTime-timePicker']} ${classes['settings-changeArrivalDepartureTime-timePicker__departure']}`}
                    defaultValue={dayjs(tripData.dateTime)}
                    showNow={false}
                    needConfirm={false}
                    placeholder={'Время отправления'}
                    format={'HH:mm'}
                    hourStep={'1'}
                    minuteStep={'5'}
                  />
                  <TimePicker
                    className={`timePicker ${classes['settings-changeArrivalDepartureTime-timePicker']} ${classes['settings-changeArrivalDepartureTime-timePicker__arrival']}`}
                    showNow={false}
                    needConfirm={false}
                    placeholder={'Время прибытия'}
                    format={'HH:mm'}
                    hourStep={'1'}
                    minuteStep={'5'}
                  />
                  <Select
                    className={`select ${classes['trip-edit-select']}`}
                    style={{width: '300px'}}
                    placeholder='Статус'
                    defaultValue={tripData.status}
                    label="true"
                    optionFilterProp='label'
                    onChange={() => {}}
                  >
                    {tripStatuses && Object.keys(tripStatuses).map((status, index) => 
                      <Select.Option value={status} label={Object.values(tripStatuses)[index]} key={Object.values(tripStatuses)[index]}>{Object.values(tripStatuses)[index]}</Select.Option>
                    )}
                  </Select>
                </div>
                <div className={classes['trip-edit-actions']}>
                  <Button onClick={() => setIsEditing(false)}>Подтвердить</Button>
                  {isEditing && 
                    <Button className={`${classes['trip-data-editButton']}`} onClick={() => setIsEditing(false)}>Отмена</Button>
                  }
                </div>
              </div>
            }
          </div>
        }
        <div className='settings'>
          <div className={classes['settings-block-container']}>
            <CollapseComponent
              className={`${classes['settings-collapse']}`}
              iconContainerClassName={classes['settings-collapse-iconContainer']}
              hasPseudos={false}
              text={
                <div className={`${classes['settings-changePrices']} ${classes['settings-block']}`}>
                  <div className={`${classes['settings-changePrices-table']}`}>
                    <p className={`${classes['settings-changePrices-description']}`}></p>
                    <p className={`${classes['settings-changePrices-description']}`}>Базовый</p>
                    <p className={`${classes['settings-changePrices-description']}`}>Комфорт</p>
                    <p className={`${classes['settings-changePrices-description']}`}>Взрослый</p>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <p className={`${classes['settings-changePrices-description']}`}>Детский</p>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <p className={`${classes['settings-changePrices-description']}`}>Багаж</p>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                  </div>
                  <Button
                    className={classes['settings-changePrices-buttonConfirm']}
                  >
                    Подтвердить
                  </Button>
                  <Button
                    className={classes['settings-changePrices-buttonConfirm']}
                  >
                    Отмена
                  </Button>
                </div>
              }
              label='Изменить стоимость билетов'
              // isWindowWidthTablet={isWindowWidthTablet}
            />
          </div>
          <div className={classes['settings-block-container']}>
            <CollapseComponent
              className={`${classes['settings-collapse']}`}
              iconContainerClassName={classes['settings-collapse-iconContainer']}
              hasPseudos={false}
              text={
                <div className={`${classes['settings-changeDiscounts']} ${classes['settings-block']}`}>
                  <div className={`${classes['settings-changePrices-table']}`}>
                    <p className={`${classes['settings-changePrices-description']}`}></p>
                    <p className={`${classes['settings-changePrices-description']}`}>Базовый</p>
                    <p className={`${classes['settings-changePrices-description']}`}>Комфорт</p>
                    <p className={`${classes['settings-changePrices-description']}`}>Взрослый</p>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <p className={`${classes['settings-changePrices-description']}`}>Детский</p>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <p className={`${classes['settings-changePrices-description']}`}>Багаж</p>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                    <Input className={`input ${classes['settings-changePrices-input']}`}/>
                  </div>
                  <Button
                    className={classes['settings-changeDiscounts-buttonConfirm']}
                  >
                    Подтвердить
                  </Button>
                  <Button
                    className={classes['settings-changeDiscounts-buttonConfirm']}
                  >
                    Отмена
                  </Button>
                </div>
              }
              label='Сделать скидку на билеты'
              // isWindowWidthTablet={isWindowWidthTablet}
            />
          </div>
          <div className={classes['settings-block-container']}>
            <CollapseComponent
              className={`${classes['settings-collapse']}`}
              iconContainerClassName={classes['settings-collapse-iconContainer']}
              hasPseudos={false}
              text={
                <div className={`${classes['settings-cancelTripDuringWeather']} ${classes['settings-block']}`}>
                  <div className={classes['settings-cancelTripDuringWeather-column']}>
                    {/* <Input
                      placeholder='Пароль'
                      type='password'
                      className={`input ${classes['settings-changePrices-input']}`}
                    /> */}
                    {isCancelTripConfirmCodeSended &&
                      <Input
                        placeholder='Код подтверждения'
                        onChange={(e) => setCancelTripConfirmCode(e.target.value)}
                        className={`input ${classes['settings-changePrices-input']}`}
                      />
                    }
                    <div className={classes['settings-cancelTripDuringWeather-controls']}>
                      <Button
                        loading={sendCancelTripConfirmCodeButtonIsLoading}
                        className={classes['settings-cancelTripDuringWeather-buttonConfirm']}
                        onClick={fetchCancelTrip}
                      >
                        {isCancelTripConfirmCodeSended ? 'Отправить код ещё раз' : 'Отправить код'}
                      </Button>
                      {isCancelTripConfirmCodeSended &&
                        <Button
                          loading={confirmCancelTripButtonIsLoading}
                          className={classes['settings-cancelTripDuringWeather-buttonConfirm']}
                          onClick={() => fetchConfirmCancelTrip()}
                        >
                          Подтвердить
                        </Button>
                      }
                    </div>
                  </div>
                    {cancelTripStatus &&
                    <div className={classes['settings-cancelTripDuringWeather-row']}>
                      <div className={classes['settings-cancelTripDuringWeather-result-status']}>
                        <div>Статус</div>
                        <div>{cancelTripStatus.status}</div>
                        <Button
                          loading={getCancelTripStatusButtonIsLoading}
                          className={classes['settings-cancelTripDuringWeather-buttonConfirm']}
                          style={{gridColumnStart: '2'}}
                          onClick={() => fetchCancelTripStatus()}
                        >
                          Обновить
                        </Button>
                      </div>
                      <div className={classes['settings-cancelTripDuringWeather-result']}>
                        <div className={classes['settings-cancelTripDuringWeather-result-property']}>Всего: </div>
                        <div className={classes['settings-cancelTripDuringWeather-result-item']}>{`${cancelTripStatus.allCount} билетов`}</div>
                        <div className={classes['settings-cancelTripDuringWeather-result-property']}>Возвращено: </div>
                        <div className={classes['settings-cancelTripDuringWeather-result-item']}>{`${cancelTripStatus.returnedCount} билетов`}</div>
                        <div className={classes['settings-cancelTripDuringWeather-result-property']}>Не возвращено: </div>
                        <div className={classes['settings-cancelTripDuringWeather-result-item']}>{`${cancelTripStatus.errorCount} билетов`}</div>
                      </div>
                    </div>
                  }
                </div>
              }
              label='Отмена рейса по погоде'
              // isWindowWidthTablet={isWindowWidthTablet}
            />
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Trip