import { createSlice } from '@reduxjs/toolkit';
 
const TicketStore = createSlice({
    name: "tickets",
    initialState: {
      _passengersDocuments: [],
      _passengersDocumentsDispatched: false,
    },
    reducers: {
      setPassengersDocuments(state, action) {
        state._passengersDocuments = [...state._passengersDocuments, action.payload];
      },
      setPassengersDocumentsDispatched(state, action) {
        state._passengersDocumentsDispatched = action.payload;
      },
      clearPassengersDocuments(state, action) {
        state._passengersDocuments = [];
      },
    }
})

export const { setPassengersDocuments, setPassengersDocumentsDispatched, clearPassengersDocuments } = TicketStore.actions;

export default TicketStore.reducer;