import React, { memo, useCallback, useState } from 'react'
import classes from './BaggageCounter.module.css'
import { beige4, maximumPassengerBaggage } from '../../utils/consts'
import Icons from '../Icons/Icons'
import { Button } from '../Button/Button'
import { Divider } from 'antd'

const BaggageCounter = memo(({baggageCount, setBaggageCount}) => {
  const changeBaggageCount = useCallback((prev, value) => {
    setBaggageCount(prev + value)
  }, [])

  return (
    <div className={classes['order-baggage-container']}>
      <div className={classes['order-baggage-title']}>
        <div>Количество </div><div>дополнительного багажа</div>
      </div>
      <Divider>
        <div className={classes['order-baggage-counter']}>
          <Button
            className={classes['order-baggage-counter-button']}
            onClick={(e) => {
              e.preventDefault()
              changeBaggageCount(baggageCount, -1)
            }}
            disabled={baggageCount == 0}
          >
            <Icons
              name='arrow-left'
              size='24px'
              stroke={beige4}
              className={classes['order-baggage-counter-button-icon__down']} 
            />
          </Button>
          <div className={classes['order-baggage-counter-total']}>{baggageCount}</div>
          <Button
            className={classes['order-baggage-counter-button']}
            onClick={(e) => {
              e.preventDefault()
              changeBaggageCount(baggageCount, +1)}
            }
            disabled={baggageCount == maximumPassengerBaggage}
          >
            <Icons
              name='arrow-left'
              size='24px'
              stroke={beige4}
              className={classes['order-baggage-counter-button-icon__up']} 
            />
          </Button>
        </div>
      </Divider>
    </div>
  )
})

export default BaggageCounter