import React from 'react'
import classes from './Profile.module.css'
import NavBar from '../../components/NavBar/NavBar'
import Wrapper from '../../components/Wrapper/Wrapper'
import { useSelector } from 'react-redux'
import H1 from '../../components/H1/H1'

const Profile = () => {
  const userInfo = useSelector(state => state.user._user.userInfo)

  return (
    <>
      <NavBar/>
      <Wrapper>
        <div className={classes['userInfo']}>
          <H1>Данные пользователя</H1>
          <div className={classes['userInfo-table']}>
            <div className={classes['userInfo-table-item']}>
              <div className={classes['userInfo-table-item-title']}>
                email
              </div>
              <div className={classes['userInfo-table-item-content']}>
                {userInfo.email}
              </div>
            </div>
            <div className={classes['userInfo-table-item']}>
              <div className={classes['userInfo-table-item-title']}>
                Номер телефона
              </div>
              <div className={classes['userInfo-table-item-content']}>
                {userInfo.phoneNumber}
              </div>
            </div>
            <div className={classes['userInfo-table-item']}>
              <div className={classes['userInfo-table-item-title']}>
                Имя
              </div>
              <div className={classes['userInfo-table-item-content']}>
                {userInfo.firstName}
              </div>
            </div>
            <div className={classes['userInfo-table-item']}>
              <div className={classes['userInfo-table-item-title']}>
                Фамилия
              </div>
              <div className={classes['userInfo-table-item-content']}>
                {userInfo.lastName}
              </div>
            </div>
            <div className={classes['userInfo-table-item']}>
              <div className={classes['userInfo-table-item-title']}>
                Отчество
              </div>
              <div className={classes['userInfo-table-item-content']}>
                {userInfo.middleName}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Profile