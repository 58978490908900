import React from 'react'
import classes from './Wrapper.module.css'

const Wrapper = ({...params}) => {
  return (
    <div className={classes.container}>
      <div className={classes["content"]}>
        {params.children}
      </div>
    </div>
  )
}

export default Wrapper