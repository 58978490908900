import { $authHost, $host } from './index'

export const getUserInfo = async () => {
  const {data} = await $authHost.get('v1/users/current');
  return data;
}

export const register = async (userData) => {
  const {data} = await $host.post('v1/users/register', userData);
  return data;
}

export const login = async (email, password) => {
  const {data} = await $host.post('v1/auth/login', {email, password});
  return data;
}

export const refresh_token = async (refreshToken) => {
  const {data} = await $host.post('v1/auth/refresh-token', {refreshToken});
  return data;
}

export const logout = async () => {
  const {data} = await $authHost.post('v1/auth/logout');
  return data;
}

export const confirmUsersEmail = async (email, code) => {
  const {data} = await $host.get(`v1/users/confirm-email?email=${email}&code=${code}`);
  return data;
}