// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../utils/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Profile_userInfo__9nTxe {
  margin-top: 44px;
  color: #274898;
  font-size: 23px;
  line-height: 1;
  font-family: "Cygre Regular";
}
@media (max-width: 500px) {
  .Profile_userInfo__9nTxe {
    font-size: 17px;
    line-height: 1;
    font-family: "Cygre Regular";
  }
}
.Profile_userInfo-table__YNZf\\+ {
  margin-top: 22px;
  display: flex;
  column-gap: 44px;
  flex-wrap: wrap;
  row-gap: 22px;
}
.Profile_userInfo-table-item__6LFwG {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #9AB0E5;
  padding: 9px 0 9px 12px;
  row-gap: 14px;
  color: #274898;
}
.Profile_userInfo-table-item-title__tMzOm {
  font-size: 23px;
  line-height: 1;
  font-family: "Cygre Regular";
}
@media (max-width: 500px) {
  .Profile_userInfo-table-item-title__tMzOm {
    font-size: 17px;
    line-height: 1;
    font-family: "Cygre Regular";
  }
}
.Profile_userInfo-table-item-content__ANi-b {
  font-size: 23px;
  line-height: 1;
  font-family: "Cygre Bold";
}
@media (max-width: 500px) {
  .Profile_userInfo-table-item-content__ANi-b {
    font-size: 17px;
    line-height: 1;
    font-family: "Cygre Bold";
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Profile/Profile.module.css"],"names":[],"mappings":"AACA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,cAAc;EACd,4BAA4B;AAC9B;AACA;EACE;IACE,eAAe;IACf,cAAc;IACd,4BAA4B;EAC9B;AACF;AACA;EACE,gBAAgB;EAChB,aAAa;EAER,gBAAgB;EACrB,eAAe;EACf,aAAa;AACf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,uBAAuB;EACvB,aAAa;EACb,cAAc;AAChB;AACA;EACE,eAAe;EACf,cAAc;EACd,4BAA4B;AAC9B;AACA;EACE;IACE,eAAe;IACf,cAAc;IACd,4BAA4B;EAC9B;AACF;AACA;EACE,eAAe;EACf,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE;IACE,eAAe;IACf,cAAc;IACd,yBAAyB;EAC3B;AACF","sourcesContent":["@import '../../utils/fonts.css';\n.userInfo {\n  margin-top: 44px;\n  color: #274898;\n  font-size: 23px;\n  line-height: 1;\n  font-family: \"Cygre Regular\";\n}\n@media (max-width: 500px) {\n  .userInfo {\n    font-size: 17px;\n    line-height: 1;\n    font-family: \"Cygre Regular\";\n  }\n}\n.userInfo-table {\n  margin-top: 22px;\n  display: flex;\n  -moz-column-gap: 44px;\n       column-gap: 44px;\n  flex-wrap: wrap;\n  row-gap: 22px;\n}\n.userInfo-table-item {\n  display: flex;\n  flex-direction: column;\n  border-left: 1px solid #9AB0E5;\n  padding: 9px 0 9px 12px;\n  row-gap: 14px;\n  color: #274898;\n}\n.userInfo-table-item-title {\n  font-size: 23px;\n  line-height: 1;\n  font-family: \"Cygre Regular\";\n}\n@media (max-width: 500px) {\n  .userInfo-table-item-title {\n    font-size: 17px;\n    line-height: 1;\n    font-family: \"Cygre Regular\";\n  }\n}\n.userInfo-table-item-content {\n  font-size: 23px;\n  line-height: 1;\n  font-family: \"Cygre Bold\";\n}\n@media (max-width: 500px) {\n  .userInfo-table-item-content {\n    font-size: 17px;\n    line-height: 1;\n    font-family: \"Cygre Bold\";\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userInfo": `Profile_userInfo__9nTxe`,
	"userInfo-table": `Profile_userInfo-table__YNZf+`,
	"userInfo-table-item": `Profile_userInfo-table-item__6LFwG`,
	"userInfo-table-item-title": `Profile_userInfo-table-item-title__tMzOm`,
	"userInfo-table-item-content": `Profile_userInfo-table-item-content__ANi-b`
};
export default ___CSS_LOADER_EXPORT___;
