import { unstable_HistoryRouter as Router, useNavigate } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { ConfigProvider } from 'antd'
import ru_RU from 'antd/locale/ru_RU';
import moment from "moment";
import 'moment/locale/ru';
import { AUTH_ROUTE, beige1, beige4, black, blue1, blue2, blue3, blue4 } from "./utils/consts";
import history from "./utils/history";

moment.locale("ru")

const App = () => {
  
  return (
    <Router history={history}>
      <ConfigProvider
        locale={ru_RU}
        theme={{
          components: {
            Input: {
              algorithm: true,
              colorBgBase: beige4,
            },
            DatePicker: {
              algorithm: true,
              colorBgBase: beige4,
              colorBgContainer: beige4,
              colorBgElevated: beige4,
              colorBgSpotlight: black,
              colorTextBase: blue1,
              cellHeight: '25px',
              borderRadius: '20px',
              colorTextHeading: blue1,
              cellActiveWithRangeBg: blue4,
            },
            Select: {
              algorithm: true,
              colorBgBase: beige4,
              colorBgContainer: beige4,
              colorBgElevated: beige4,
              colorTextBase: blue1,
              borderRadius: '20px',
              optionPadding: '10px 20px',
              fontSize: '23px,',
            },
            Segmented: {
              algorithm: true,
              borderRadius: '100px',
              paddingContentHorizontal: '50px',
              colorBgBase: beige1,
              colorBgTextActive: blue1,
              colorTextBase: beige4,
              itemHoverBg: blue2,
              itemSelectedBg: blue1,
            },
            Checkbox: {
              colorBgContainer: 'transparent',
              borderRadiusSM: '0px',
              colorBorder: blue3,
              colorPrimary: blue2,
              colorPrimaryHover: blue1,
            },
            Modal: {
              colorBgBase: blue1,
              colorTextHeading: blue1,
            },
            Divider: {
              colorSplit: blue3,
              colorTextHeading: blue1,
              textPaddingInline: '8px',
              lineWidth: '1px',
            }
          },
        }}
      >
        <AppRouter/>
      </ConfigProvider>
    </Router>
  );
}

export default App;