import { ADMIN_ROUTE, AUTH_ROUTE, NOTFOUND_ROUTE, PROFILE_ROUTE, TICKET_ROUTE, SETTINGS_ROUTE, TICKETS_ROUTE, TRIP_ROUTE, TRIPS_ROUTE, USERS_ROUTE } from "./utils/consts";
import Auth from './pages/Auth/Auth';
import { lazy } from "react"; 
import Trips from "./pages/Trips/Trips";
import Tickets from "./pages/Tickets/Tickets";
import Users from "./pages/Users/Users";
import Profile from "./pages/Profile/Profile";
import Trip from "./pages/Trip/Trip";
import Ticket from "./pages/Ticket/Ticket";
import Settings from "./pages/Settings/Settings";

const NotFound = lazy(() => import('./pages/NotFound/NotFound'))
const Admin = lazy(() => import('./pages/Admin/Admin'))

export const authRoutes = [
  {
    path: ADMIN_ROUTE,
    Component: Admin
  },
  {
    path: TRIPS_ROUTE,
    Component: Trips
  },
  {
    path: TICKETS_ROUTE,
    Component: Tickets
  },
  {
    path: USERS_ROUTE,
    Component: Users
  },
  {
    path: PROFILE_ROUTE,
    Component: Profile
  },
  {
    path: TRIP_ROUTE,
    Component: Trip
  },
  {
    path: TICKET_ROUTE,
    Component: Ticket
  },
  {
    path: SETTINGS_ROUTE,
    Component: Settings
  },
]

export const publicRoutes = [
  {
    path: AUTH_ROUTE,
    Component: Auth
  },
  {
    path: NOTFOUND_ROUTE,
    Component: NotFound
  },
]