import React from 'react'
import IconsSVG from '../../assets/icons.svg'

const Icons = ({name, fill, stroke, size, className, style}) => {
  return (
    <svg className={`icon icon-${name} ${className}`} fill={fill} stroke={stroke} width={size} height={size} style={style}>
        <use xlinkHref={`${IconsSVG}#icon-${name}`} />
    </svg>
  )
}

export default Icons