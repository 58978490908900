import React, { useEffect, useState } from 'react'
import classes from './Tickets.module.css'
import Wrapper from '../../components/Wrapper/Wrapper'
import NavBar from '../../components/NavBar/NavBar'
import H1 from '../../components/H1/H1'
import { Button } from '../../components/Button/Button'
import { DatePicker, Input, Pagination, Select } from 'antd'
import moment from 'moment'
import { getOrdersList } from '../../http/admin'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { createSearchParams, NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { TICKET_ROUTE, TICKETS_ROUTE } from '../../utils/consts'
import { getStorageWithExpiry } from '../../utils/getStorageWithExpiry'

const Tickets = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const [ordersList, setOrdersList] = useState(null)
  const [ordersListLimit, setOrdersListLimit] = useState(params.get('limit') || 50)
  const [ordersListOffset, setOrdersListOffset] = useState(params.get('offset') || 0)
  const [ordersListPage, setOrdersListPage] = useState(params.get('page') || 1)
  const [ordersListTotalCount, setOrdersListTotalCount] = useState(0)
  const [ordersListOrderBy, setOrdersListOrderBy] = useState(params.get('orderBy') || 'createdAt')
  const [ordersListOrderByDirection, setOrdersListOrderByDirection] = useState(params.get('orderByDirection') || 'desc')
  const allDictionaries = useSelector(state => state.dictionary._dictionaries)
  const orderStatuses = allDictionaries.orderStatuses

  const [inputOrderNumberStatus, setInputOrderNumberStatus] = useState('normal')
  const [inputOrderNumberPlaceholder, setInputOrderNumberPlaceholder] = useState('Номер заказа')
  const [ordersListOrderNumber, setOrderNumber] = useState(params.get('orderNumber') !== 'null' ? params.get('orderNumber') : null)
  const [inputLastNameStatus, setInputLastNameStatus] = useState('normal')
  const [inputLastNamePlaceholder, setInputLastNamePlaceholder] = useState('Фамилия')
  const [ordersListLastName, setLastName] = useState(params.get('lastName') !== 'null' ? params.get('lastName') : null)
  const [email, setEmail] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [status, setStatus] = useState(null)
  const [trip, setTrip] = useState(null)
  const [bookingIsFound , setBookingIsFound] = useState(false)
  const [ticketIsChanging, setTicketIsChanging] = useState(false)
  const [passengersData, setPassengersData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const fetchOrdersList = async (limit = null, offset = null, orderBy = null, orderByDirection = null, orderNumber = null, lastName = null) => {
    setIsLoading(true)
    try {
      console.log(ordersListOrderNumber, ordersListLastName)
      console.log(limit === null ? ordersListLimit : limit, offset === null ? ordersListOffset : offset, ordersListOrderNumber ? ordersListOrderNumber : orderNumber, ordersListLastName ? ordersListLastName : lastName, orderBy ? orderBy : ordersListOrderBy, orderByDirection ? orderByDirection : ordersListOrderByDirection)
      let {data} = await getOrdersList(
        limit === null ? ordersListLimit : limit,
        offset === null ? ordersListOffset : offset,
        (ordersListOrderNumber && ordersListOrderNumber !== 'null') ? ordersListOrderNumber : orderNumber,
        (ordersListLastName && ordersListLastName !== 'null') ? ordersListLastName : lastName,
        orderBy ? orderBy : ordersListOrderBy,
        orderByDirection ? orderByDirection : ordersListOrderByDirection)
      console.log(data)
      setBookingIsFound(true)
      setOrdersList(data.items)
      setOrdersListTotalCount(data.total)
    } catch (e) {
      console.log(e)
      setBookingIsFound(false)
      try {
        let errors = e.response.data.data
        if (errors) {
          errors.errors.map((error) => {
            // returnMessage('error', e.response.status, `${error.propertyPath == null ? '' : `${error.propertyPath} - `}${error.message}`, '', '', 4)
          })
        }
      } catch (e) {
        // returnMessage('error', e.response.status, e.response.data.message, '', '', 5)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const changeOrdersListPage = (page, pageSize) => {
    setOrdersListOffset(page * pageSize - pageSize)
    fetchOrdersList(pageSize, page * pageSize - pageSize, ordersListOrderBy, ordersListOrderByDirection, ordersListOrderNumber, ordersListLastName)
    changeParams(page, pageSize, page * pageSize - pageSize, ordersListOrderBy, ordersListOrderByDirection, ordersListOrderNumber, ordersListLastName)
  }

  const changeOrdersListLimit = (limit) => {
    let currentItem = ordersListPage * ordersListLimit
    setOrdersListLimit(limit)
    let page = ordersListPage
    if (currentItem > ordersListTotalCount) {
      page = Math.ceil(ordersListTotalCount/limit)
    }
    else {
      page = Math.floor(ordersListOffset/limit + 1)
    }
    setOrdersListPage(page)
    setOrdersListOffset(page * limit - limit)
    changeParams(page, limit, page * limit - limit, ordersListOrderBy, ordersListOrderByDirection, ordersListOrderNumber, ordersListLastName)
    if (ordersList.length > 0) {
      fetchOrdersList(limit, page * limit - limit, ordersListOrderBy, ordersListOrderByDirection, ordersListOrderNumber, ordersListLastName)
    }
  }

  const changeOrdersListOrderBy = (value) => {
    setOrdersListOrderBy(value)
    changeParams(ordersListPage, ordersListLimit, ordersListOffset, value, ordersListOrderByDirection, ordersListOrderNumber, ordersListLastName)
    if (ordersList.length > 0) {
      fetchOrdersList(ordersListLimit, ordersListOffset, value, ordersListOrderByDirection, ordersListOrderNumber, ordersListLastName)
    }
  }

  const changeOrdersListOrderByDirection = (value) => {
    setOrdersListOrderByDirection(value)
    changeParams(ordersListPage, ordersListLimit, ordersListOffset, ordersListOrderBy, value, ordersListOrderNumber, ordersListLastName)
    if (ordersList.length > 0) {
      fetchOrdersList(ordersListLimit, ordersListOffset, ordersListOrderBy, value, ordersListOrderNumber, ordersListLastName)
    }
  }

  const changeParams = (page, limit, offset, orderBy, orderByDirection, orderNumber,lastName) => {
    navigate({
      pathname: TICKETS_ROUTE,
      search: `?${createSearchParams({
        page: page,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        orderByDirection: orderByDirection,
        orderNumber: orderNumber,
        lastName: lastName,
      })}`
    })
  }

  useEffect(() => {
    fetchOrdersList()
    if (!params.get('page')) {
      changeParams(1, 50, 0, 'createdAt', 'desc', null, null)
    }
  }, [getStorageWithExpiry('accessToken')])
  
  return (
    <>
      <NavBar/>
      <Wrapper>
        <H1>Заказы</H1>
        <div className={`${classes['ordersList-search-wrapper']}`}>
          <div className={`${classes['ordersList-search']} ${classes['ordersList-search__filters']}`}>
            <Input
              className={`input ${classes['ordersList-search-input']}`}
              style={{width: '300px'}}
              value={ordersListOrderNumber}
              placeholder={inputOrderNumberPlaceholder}
              onChange={function(e) {
                setOrderNumber(e.target.value)
                setInputOrderNumberStatus('ok')
              }}
              status={inputOrderNumberStatus}
            />
            <Input
              className={`input ${classes['ordersList-search-input']}`}
              style={{width: '300px'}}
              value={ordersListLastName}
              placeholder={inputLastNamePlaceholder}
              onChange={function(e) {
                setLastName(e.target.value.toString())
                setInputLastNameStatus('ok')
              }}
              status={inputLastNameStatus}
            />
            <Button
              onClick={function(e) {
                setOrdersListOffset(0)
                setOrdersListPage(1)
                changeParams(ordersListPage, ordersListLimit, 0, ordersListOrderBy, ordersListOrderByDirection, ordersListOrderNumber, ordersListLastName ? ordersListLastName : null)
                fetchOrdersList(ordersListLimit, 0)
              }}
              className={classes['ordersList-button__confirm']}
            >
              Найти
            </Button>
          </div>
        </div>
        <div className={classes['ordersList-list-wrapper']}>
          <div className={classes['ordersList-search']}>
            {!(ordersListTotalCount < ordersListLimit) &&
              <Pagination
                hideOnSinglePage
                rootClassName={'pagination'}
                disabled={ordersListTotalCount < ordersListLimit}
                current={ordersListPage}
                defaultCurrent={ordersListPage}
                defaultPageSize={ordersListLimit}
                pageSize={ordersListLimit}
                total={ordersListTotalCount}
                showSizeChanger={false}
                onChange={(page, pageSize) => {
                  setOrdersListPage(page)
                  changeOrdersListPage(page, pageSize)
                }}
              />
            }
            <div className={classes['ordersList-search-column']}>
              <Select
                className={`select ${classes['ordersList-search-select']}`}
                value={ordersListOrderBy}
                style={{width: '300px'}}
                placeholder='Сортировать по полю'
                label="true"
                optionFilterProp='label'
                onChange={(value) => changeOrdersListOrderBy(value)}
              >
                <Select.Option value='number'>Номер заказа</Select.Option>
                <Select.Option value='passengersCount'>Количество пассажиров</Select.Option>
                <Select.Option value='price'>Стоимость</Select.Option>
                <Select.Option value='email'>email</Select.Option>
                <Select.Option value='phoneNumber'>Номер телефона</Select.Option>
                <Select.Option value='status'>Статус</Select.Option>
                <Select.Option value='createdAt'>Дата создания</Select.Option>
              </Select>
              <Select
                className={`select ${classes['ordersList-search-select']}`}
                value={ordersListOrderByDirection}
                style={{width: '300px'}}
                placeholder='Сортировать по направлению'
                label="true"
                optionFilterProp='label'
                onChange={(value) => changeOrdersListOrderByDirection(value)}
              >
                <Select.Option value='asc'>По возрастанию</Select.Option>
                <Select.Option value='desc'>По убыванию</Select.Option>
              </Select>
              <Select
                className={`select ${classes['ordersList-search-select']}`}
                value={ordersListLimit}
                style={{width: '100px'}}
                placeholder='Количество записей'
                label="true"
                optionFilterProp='label'
                onChange={function(value) {
                  changeOrdersListLimit(value)
                }}
              >
                <Select.Option value={10} key={10} label='10'>10</Select.Option>
                <Select.Option value={25} key={25} label='25'>25</Select.Option>
                <Select.Option value={50} key={50} label='50'>50</Select.Option>
                <Select.Option value={100} key={100} label='100'>100</Select.Option>
              </Select>
            </div>
          </div>
          <div className={classes['ordersList-list']}>
            <div className={classes['ordersList-list-header']}>
              <div className={`${classes['ordersList-list-header-item']}`}>
                <div className={`${classes['ordersList-list-header-item-title']}`}>Номер заказа</div>
              </div>
              <div className={`${classes['ordersList-list-header-item']}`}>
                <div className={`${classes['ordersList-list-header-item-title']}`}>Количество пассажиров</div>
              </div>
              <div className={`${classes['ordersList-list-header-item']}`}>
                <div className={`${classes['ordersList-list-header-item-title']}`}>Стоимость</div>
              </div>
              <div className={`${classes['ordersList-list-header-item']}`}>
                <div className={`${classes['ordersList-list-header-item-title']}`}>email</div>
              </div>
              <div className={`${classes['ordersList-list-header-item']}`}>
                <div className={`${classes['ordersList-list-header-item-title']}`}>Номер телефона</div>
              </div>
              <div className={`${classes['ordersList-list-header-item']}`}>
                <div className={`${classes['ordersList-list-header-item-title']}`}>Статус</div>
              </div>
              <div className={`${classes['ordersList-list-header-item']}`}>
                <div className={`${classes['ordersList-list-header-item-title']}`}>Дата создания</div>
              </div>
              <div className={`${classes['ordersList-list-header-item']}`}>
                <div className={`${classes['ordersList-list-header-item-title']}`}>Действия</div>
              </div>
            </div>
            {!ticketIsChanging && ordersList !== null && ordersList.map((ticket, index) => 
              <div className={classes['ordersList-list-order']} key={ticket.id} >
                <div className={classes['ordersList-list-order-data']}>
                  <div className={`${classes['ordersList-list-order-data-item']}`}>
                    <div className={`${classes['ordersList-list-order-data-item-value']}`}>{ticket.number}</div>
                  </div>
                  <div className={`${classes['ordersList-list-order-data-item']}`}>
                    <div className={`${classes['ordersList-list-order-data-item-value']}`}>{ticket.passengersCount}</div>
                  </div>
                  <div className={`${classes['ordersList-list-order-data-item']}`}>
                    <div className={`${classes['ordersList-list-order-data-item-value']}`}>{ticket.price}</div>
                  </div>
                  <div className={`${classes['ordersList-list-order-data-item']}`}>
                    <div className={`${classes['ordersList-list-order-data-item-value']}`}>{ticket.email ? ticket.email : '-'}</div>
                  </div>
                  <div className={`${classes['ordersList-list-order-data-item']}`}>
                    <div className={`${classes['ordersList-list-order-data-item-value']}`}>{ticket.phoneNumber ? ticket.phoneNumber : '-'}</div>
                  </div>
                  <div className={`${classes['ordersList-list-order-data-item']}`}>
                    <div className={`${classes['ordersList-list-order-data-item-value']}`}>{orderStatuses[`${ticket.status}`]}</div>
                  </div>
                  <div className={`${classes['ordersList-list-order-data-item']}`}>
                    <div className={`${classes['ordersList-list-order-data-item-value']}`}>{dayjs(ticket.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                  </div>
                  <div className={`${classes['ordersList-list-order-data-item']}`}>
                    <div className={`${classes['ordersList-list-order-data-item-value']} ${classes['ordersList-list-order-data-item-value__actions']}`}>
                      <NavLink to={`${TICKET_ROUTE}?id=${ticket.id}`}>
                        <Button
                          className={`${classes['tripsList-list-trip-data-editButton']}`}
                          // onClick={() => {dispatch(setTrip(trip))}}
                        >
                          Подробнее
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
                // <div className={classes['ordersList-list-order-footer']}>
                //   <Button
                //     className={`${classes['ordersList-list-order-footer-button']} ${passenger.status != 'paid' && `${classes['ordersList-list-order__canceled-footer-button']}`}`}
                //     onClick={() => {
                //       setTicketIsChanging(true)
                //       setPassengersData(passenger)
                //       // setPassengersSeat(passenger)s
                //     }}
                //   >
                //     Изменить
                //   </Button>
                // </div>
            )}
            {ticketIsChanging && 
              <div>
                {/* {ordersList.order.map((passenger, index) => 
                  <PassengerInfo
                    passengerInfo={passenger}
                    key={passenger.seatNumber}
                    values={passenger.document}
                    baggage={passenger.baggageCount}
                    index={index}
                    changePassengersBaggage={changePassengersBaggage}
                    setDataForCalculatingChanged={setDataForCalculatingChanged}
                    // dataForCalculatingChanged={dataForCalculatingChanged}
                    // setHasUnsavedChanges={setHasUnsavedChanges}
                    // returnMessage={returnMessage}
                    // isModalOrderCreatedOpen={isModalOrderCreatedOpen}
                  />
                )} */}
                <div className={classes['ordersList-list-order-footer']}>
                  <Button
                    className={`${classes['ordersList-list-order-footer-button__cancel']}`}
                    onClick={() => {
                      setTicketIsChanging(false)
                    }}
                  >
                    Отмена
                  </Button>
                  <div className={classes['ordersList-list-order-footer-controls']}>
                    <div className={classes['ordersList-list-order-footer-controls-item']}>
                      <label className={classes['ordersList-list-order-footer-controls-label']}>
                        <Input
                          className={classes['ordersList-list-order-footer-controls-input']}
                          placeholder='Пароль'
                        />
                      </label>
                      <Button
                        className={`${classes['ordersList-list-order-footer-button']}`}
                        onClick={() => {
                          
                        }}
                        >
                        Отправить код
                      </Button>
                    </div>
                    <div className={classes['ordersList-list-order-footer-controls-item']}>
                      <label className={classes['ordersList-list-order-footer-controls-label']}>
                      <Input
                        className={classes['ordersList-list-order-footer-controls-input']}
                        placeholder='Код подтверждения'
                      />
                      </label>
                      <Button
                      className={`${classes['ordersList-list-order-footer-button']}`}
                      onClick={() => {
                        
                      }}
                      >
                      Подтвердить
                      </Button>
                    </div>
                      <Button
                      className={`${classes['ordersList-list-order-footer-button']}`}
                      onClick={() => {

                      }}
                      >
                      Изменить билет
                      </Button>
                  </div>
                </div>
              </div>
            }
          </div>
          {!(ordersListTotalCount < ordersListLimit) &&
            <Pagination
              hideOnSinglePage
              rootClassName={'pagination'}
              disabled={ordersListTotalCount < ordersListLimit}
              current={ordersListPage}
              defaultCurrent={ordersListPage}
              defaultPageSize={ordersListLimit}
              pageSize={ordersListLimit}
              total={ordersListTotalCount}
              showSizeChanger={false}
              onChange={(page, pageSize) => {
                setOrdersListPage(page)
                changeOrdersListPage(page, pageSize)
              }}
            />
          }
        </div>
      </Wrapper>
    </>
  )
}

export default Tickets