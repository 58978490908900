// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../utils/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H1_h1-desktop__3KwSd {
  font-size: 51px;
  line-height: 1;
  font-family: "Cruinn Black";
  color: #274898;
}
@media (max-width: 500px) {
  .H1_h1-desktop__3KwSd {
    font-size: 26px;
    line-height: 1;
    font-family: "Cruinn Black";
  }
}

.H1_schedule-title__e4T1b {
  height: 36px;
}

@media (max-width: 500px) {
  .H1_h1-desktop__3KwSd {
    display: flex;
    justify-content: center;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/H1/H1.module.css"],"names":[],"mappings":"AACA;EACE,eAAe;EACf,cAAc;EACd,2BAA2B;EAC3B,cAAc;AAChB;AACA;EACE;IACE,eAAe;IACf,cAAc;IACd,2BAA2B;EAC7B;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,aAAa;IACb,uBAAuB;EACzB;AACF","sourcesContent":["@import '../../utils/fonts.css';\n.h1-desktop {\n  font-size: 51px;\n  line-height: 1;\n  font-family: \"Cruinn Black\";\n  color: #274898;\n}\n@media (max-width: 500px) {\n  .h1-desktop {\n    font-size: 26px;\n    line-height: 1;\n    font-family: \"Cruinn Black\";\n  }\n}\n\n.schedule-title {\n  height: 36px;\n}\n\n@media (max-width: 500px) {\n  .h1-desktop {\n    display: flex;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"h1-desktop": `H1_h1-desktop__3KwSd`,
	"schedule-title": `H1_schedule-title__e4T1b`
};
export default ___CSS_LOADER_EXPORT___;
