import React, { useState, memo, useCallback, useEffect, useRef } from 'react'
import classes from './PassengerInfo.module.css'
import { DatePicker, Divider, Input, Select } from 'antd'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { clearPassengersDocuments, setPassengersDocuments, setPassengersDocumentsDispatched } from '../../store/TicketStore'
import BaggageCounter from '../BaggageCounter/BaggageCounter'
import { getCountries } from '../../http/dictionary'
import { setCountries } from '../../store/DictionaryStore'
import { Button } from '../Button/Button'
import { windowWidthMobile, windowWidthTablet } from '../../utils/consts'

const PassengerInfo = memo(({passengerInfo, formRef, index, changePassengersBaggage, baggage, setDataForCalculatingChanged, dataForCalculatingChanged, setHasUnsavedChanges, returnMessage, isModalOrderCreatedOpen, values, isEditing}) => {
  const [canBeTriggered, setCanBeTriggered] = useState(false)
  const dispatch = useDispatch()
  const passengersDocuments = useSelector(state => state.ticket._passengersDocuments)
  const [baggageCount, setBaggageCount] = useState(baggage || 0)
  const countries = useSelector(state => state.dictionary._countries)
  const [hasChild, setHasChild] = useState(false)
  const [formChanged, setFormChanged] = useState(false)
  const dictionaries = useSelector(state => state.dictionary._dictionaries)
  const documentTypes = dictionaries.documentTypes
  const genders = dictionaries.genders
  const [isWindowWidthTablet, setIsWindowWidthTablet] = useState(window.innerWidth <= windowWidthTablet)
  const [isWindowWidthMobile, setIsWindowWidthMobile] = useState(window.innerWidth <= windowWidthMobile)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { errors, defaultValues },
  } = useForm({
    defaultValues: {
      name: values ? values.firstName : '',
      lastName: values ? values.lastName : '',
      middleName: values ? values.middleName : '',
      gender: values ? values.gender : '',
      documentType: values ? values.documentType : '',
      documentNumber: values ? values.documentNumber : '',
      citizenship: values ? values.countryCode : '',
      birthday: values ? values.birthday : '',
      childName: values ? values.childName : '',
      childLastName: values ? values.childLastName : '',
      childMiddleName: values ? values.childMiddleName : '',
      childGender: values ? values.childGender : '',
      childDocumentType: 'birthCertificate',
      childDocumentNumber: values ? values.childDocumentNumber : '',
      childCitizenship: values ? values.childCitizenship : '',
      childBirthday: values ? values.childBirthday : '',
    }
  })

  console.log('values: ', values)
  console.log('defaultValues: ', defaultValues)

  const fetchCountries = async () => {
    try {
      let {data} = await getCountries()
      console.log(data)
      dispatch(setCountries(data))
    } catch (e) {
      console.log(e)
      returnMessage('error', e.response.status, e.response.data.message, '', '', 10)
    }
  }

  const calculate = async () => {
    console.log(getValues('birthday'))
    if (getValues('birthday') !== '' && getValues('birthday') !== null) {
      setTimeout(() => {
        const passenger = hasChild ? {
          "seatNumber": `${passengerInfo.seatNumber}`,
          "baggageCount": baggageCount,
          "document": {
            "birthday": getValues('birthday'),
          },
          "child": {
            "document": {
              "birthday": getValues('childBirthday'),
            }
          }
        } : {
          "seatNumber": `${passengerInfo.seatNumber}`,
          "baggageCount": baggageCount,
          "document": {
            "birthday": getValues('birthday'),
          },
        }
        console.log(passenger)
        dispatch(setPassengersDocuments(passenger))
        dispatch(setPassengersDocumentsDispatched(true))
      }, 100);
    }
    setDataForCalculatingChanged(false)
  }

  const getChangeHandlerWithValue = useCallback(name => value => {
    console.log(name)
    if (value == '' || value == null) {
      setValue(name, null)
    }
    else if (name == 'name' || name == 'lastName' || name == 'middleName') {
      setValue(name, value.target.value.toString().toUpperCase())
    }
    else if (value.target) {
      setValue(name, value.target.value)
    }
    else if (name == "birthday" || name == "childBirthday") {
      setValue(name, moment(value.$d).format('YYYY-MM-DD'))
      if (moment(value.$d).format('YYYY-MM-DD') != '' && moment(value.$d).format('YYYY-MM-DD') != null) setDataForCalculatingChanged(true)
    }
    else {
      setValue(name, value)
    }
    if (!name.includes('child')) {
      if (canBeTriggered) trigger(name)
    }
    setFormChanged(!formChanged)
    if (JSON.stringify(getValues()) !== JSON.stringify(defaultValues)) {
      setHasUnsavedChanges(true)
    } else setHasUnsavedChanges(false)
  }, [])

  const onSubmit = (data) => {
    console.log(`данные формы: `, data)
    setCanBeTriggered(false)
    const passenger = hasChild ? {
      "seatNumber": `${passengerInfo.seatNumber}`,
      "baggageCount": baggageCount,
      "document": {
        "documentType": `${data.documentType}`,
        "documentNumber": `${data.documentNumber}`,
        "firstName": `${data.name}`,
        "lastName": `${data.lastName}`,
        "middleName": data.middleName == "" || data.middleName == null ? null : `${data.middleName}`,
        "gender": `${data.gender}`,
        "birthday": `${data.birthday}`,
        "countryCode": `${data.citizenship}`,
      },
      "child": {
        "document": {
          "documentType": 'birthCertificate',
          "documentNumber": `${data.childDocumentNumber}`,
          "firstName": `${data.childName}`,
          "lastName": `${data.childLastName}`,
          "middleName": data.childMiddleName == "" || data.middleName == null ? null : `${data.childMiddleName}`,
          "gender": `${data.childGender}`,
          "birthday": `${data.childBirthday}`,
          "countryCode": `${data.childCitizenship}`,
        }
      }
    } : {
      "seatNumber": `${passengerInfo.seatNumber}`,
      "baggageCount": baggageCount,
      "document": {
        "documentType": `${data.documentType}`,
        "documentNumber": `${data.documentNumber}`,
        "firstName": `${data.name}`,
        "lastName": `${data.lastName}`,
        "middleName": data.middleName == "" || data.middleName == null ? null : `${data.middleName}`,
        "gender": `${data.gender}`,
        "birthday": `${data.birthday}`,
        "countryCode": `${data.citizenship}`,
      }
    }
    dispatch(setPassengersDocuments(passenger))
    dispatch(setPassengersDocumentsDispatched(true))
  }

  const onWindowWidthChange = () => {
    setIsWindowWidthTablet(window.innerWidth <= windowWidthTablet)
    setIsWindowWidthMobile(window.innerWidth <= windowWidthMobile)
  }

  useEffect(() => {
    console.log(passengerInfo)
    changePassengersBaggage(index, baggageCount, passengerInfo.seatNumber)
    if (getValues('birthday') !== '' && getValues('birthday') !== null) {
      setDataForCalculatingChanged(true)
    }
  }, [baggageCount])

  useEffect(() => {
    if (!countries.length) {
      fetchCountries()
    }

    window.addEventListener('resize', onWindowWidthChange)
    return () => window.removeEventListener('resize', onWindowWidthChange)
  }, [])

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if ((JSON.stringify(getValues()) !== JSON.stringify(defaultValues)) && !isModalOrderCreatedOpen) {
        e.preventDefault()
        return (e.returnValue = '')
      }
    }
    window.addEventListener("beforeunload", onBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload)
    }
  }, [formChanged])

  useEffect(() => {
    if (dataForCalculatingChanged === true) {
      console.log(dataForCalculatingChanged)
      calculate()
    }
  }, [dataForCalculatingChanged])

  useEffect(() => {
    if (isModalOrderCreatedOpen) {
      reset()
    }
  }, [isModalOrderCreatedOpen])
  
	return (
    <div className={classes['passengerInfo-container']}>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className={classes['passengerInfo']}>
        <div className={classes['passengerInfo-mainDocument']}>
          <div className={`${classes['passengerInfo-header']}`}>
            <div className={`${classes['passengerInfo-header-label']} ${classes['passengerInfo-header-passengerNumber']}`}>Пассажир {index + 1}</div>
            <div className={classes['passengerInfo-header-separator']}></div>
            <div className={`${classes['passengerInfo-header-label']} ${classes['passengerInfo-header-seatNumber']}`}>Место {passengerInfo.seatNumber}</div>
          </div>
          <div className={classes['passengerInfo-row']}>
            <div className={classes['passengerInfo-label']}>
              {isEditing &&            
                <label className={`${classes['passengerInfo-input-title']}`}>Фамилия</label>
              }
              <Input
                className={classes['passengerInfo-input']}
                placeholder='Фамилия'
                defaultValue={defaultValues.lastName}
                {...register("lastName", {
                  required: "Фамилия - обязательное поле",
                })}
                onChange={
                  getChangeHandlerWithValue("lastName")
                }
              />
              <div className={classes['passengerInfo-validationError-message']}>
                {errors.lastName && errors.lastName.message}
              </div>
            </div>
            <div className={classes['passengerInfo-label']}>
              {isEditing &&            
                <label className={`${classes['passengerInfo-input-title']}`}>Имя</label>
              }
              <Input
                className={classes['passengerInfo-input']}
                placeholder='Имя'
                defaultValue={defaultValues.name}
                {...register("name", {
                  required: "Имя - обязательное поле",
                  pattern: {
                    value: /[^0-9._%+-]/,
                    message: 'В поле можно вводить только буквы',
                  }
                })}
                onChange={
                  getChangeHandlerWithValue("name")
                }
              />
              <div className={classes['passengerInfo-validationError-message']}>
                {errors.name && errors.name.message}
              </div>
            </div>
            <div className={classes['passengerInfo-label']}>
              {isEditing &&            
                <label className={`${classes['passengerInfo-input-title']}`}>Отчество</label>
              }
              <Input
                className={classes['passengerInfo-input']}
                placeholder='Отчество'
                defaultValue={defaultValues.middleName}
                {...register("middleName")}
                onChange={
                  getChangeHandlerWithValue("middleName")
                }
              />
              <div className={classes['passengerInfo-validationError-message']}>
                {errors.middleName && errors.middleName.message}
              </div>
            </div>
            <div className={classes['passengerInfo-label']}>
              {isEditing &&            
                <label className={`${classes['passengerInfo-input-title']}`}>Пол</label>
              }
              <Select
                className={`${classes['passengerInfo-select']} ${'passengerInfo-select'}`}
                placeholder='Пол'
                defaultValue={defaultValues.gender || null}
                {...register("gender", {
                  required: "Пол - обязательное поле",
                })}
                onChange={
                  getChangeHandlerWithValue("gender")
                }
              >
                <Select.Option value='m' label={genders.m}>{genders.m}</Select.Option>
                <Select.Option value='f' label={genders.f}>{genders.f}</Select.Option>
              </Select>
              <div className={classes['passengerInfo-validationError-message']}>
                {errors.gender && errors.gender.message}
              </div>
            </div>
          </div>
          <div className={classes['passengerInfo-document']}>
            <Divider orientation="left">
              Документ
            </Divider>
          </div>
          <div className={classes['passengerInfo-row']}>
            <div className={classes['passengerInfo-label']}>
              {isEditing &&            
                <label className={`${classes['passengerInfo-input-title']}`}>Документ</label>
              }
              <Select
                className={`${classes['passengerInfo-select']} ${'passengerInfo-select'}`}
                placeholder='Документ'
                defaultValue={defaultValues.documentType || null}
                {...register("documentType", {
                  required: "Документ - обязательное поле",
                })}
                onChange={
                  getChangeHandlerWithValue("documentType")
                }
              >
                <Select.Option value='passport' label={documentTypes.passport}>{documentTypes.passport}</Select.Option>
                <Select.Option value='birthCertificate' label={documentTypes.birthCertificate}>{documentTypes.birthCertificate}</Select.Option>
              </Select>
              <div className={classes['passengerInfo-validationError-message']}>
                {errors.documentType && errors.documentType.message}
              </div>
            </div>
            <div className={classes['passengerInfo-label']}>
              {isEditing &&            
                <label className={`${classes['passengerInfo-input-title']}`}>Номер документа</label>
              }
              <Input
                className={classes['passengerInfo-input']}
                placeholder='Номер документа'
                defaultValue={defaultValues.documentNumber}
                {...register("documentNumber", {
                  required: "Номер документа - обязательное поле",
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Номер должен состоять только из цифр",
                  },
                  validate: {
                    toShort: (value) => (value.length === 10) || "Номер должен состоять из 10 цифр",
                    // onlyNums: (value) => value.match(/^[0-9]*$/) || "Номер должен состоять только из цифр",
                  }
                })}
                onChange={
                  getChangeHandlerWithValue("documentNumber")
                }
              />
              <div className={classes['passengerInfo-validationError-message']}>
                {errors.documentNumber && errors.documentNumber.message}
              </div>
            </div>
            <div className={classes['passengerInfo-label']}>
              {isEditing &&            
                <label className={`${classes['passengerInfo-input-title']}`}>Гражданство</label>
              }
              <Select
                className={`${classes['passengerInfo-select']} ${'passengerInfo-select'}`}
                placeholder='Гражданство'
                defaultValue={defaultValues.citizenship || null}
                {...register("citizenship", {
                  required: "Гражданство - обязательное поле",
                })}
                onChange={
                  getChangeHandlerWithValue("citizenship")
                }
                showSearch
                label="true"
                optionFilterProp='label'
              >
                {countries.map((country) =>
                  <Select.Option value={country.code} label={country.name} key={country.code}>{country.name}</Select.Option>
                )}
                {}
              </Select>
              <div className={classes['passengerInfo-validationError-message']}>
                {errors.citizenship && errors.citizenship.message}
              </div>
            </div>
            <div className={classes['passengerInfo-label']}>
              {isEditing &&            
                <label className={`${classes['passengerInfo-input-title']}`}>Дата рождения</label>
              }
              <div className={classes['passengerInfo-validationError-message']}>
                {errors.birthday && errors.birthday.message}
              </div>
              <DatePicker
                // ref={datapicker}
                // className={`${classes['passengerInfo-datePick']} ${'passengerInfo-datePick'}`}
                className='passengerInfo-datePick'
                popupClassName='passengerInfo-datePick-popup'
                minDate={dayjs().add(-130, "years")}
                maxDate={dayjs().add(-2, "years")}
                format='DD.MM.YYYY'
                defaultValue={defaultValues.birthday ? dayjs(defaultValues.birthday) : null}
                defaultPickerValue={getValues('birthday') ? dayjs(getValues('birthday')) : dayjs().add(-18, "years")}
                placement='topRight'
                placeholder='Дата рождения'
                // onCalendarChange={() => getChangeHandlerWithValue("birthday")}
                // getPopupContainer={trigger => trigger.parentElement}
                {...register("birthday", {
                  required: "Дата рождения - обязательное поле",
                })}
                onChange={(e) => getChangeHandlerWithValue("birthday")(e)}
              />
            </div>
          </div>
        </div>
        {!isEditing &&        
          <div className={classes['passengerInfo-child']}>
            {isWindowWidthMobile
            ?
              <div className={classes['passengerInfo-child-addButton-label']}>
                Добавить билет для ребенка до 2х лет <span className={classes['passengerInfo-child-thinText']}>(без предоставления места)</span>
              </div>
            :
              <Divider orientation="left">
                Добавить билет для ребенка до 2х лет <span className={classes['passengerInfo-child-thinText']}>(без предоставления места)</span>
              </Divider>
            }
            <Button
              className={`${classes['passengerInfo-child-addButton']}`}
              onClick={(e) => {
                e.preventDefault()
                if (hasChild) {
                  setHasChild(false)
                  setDataForCalculatingChanged(true)
                }
                else {
                  setHasChild(true)
                }
              }}
            >
              {hasChild ? 'Удалить' : 'Добавить'}
            </Button>
          </div>
        }
        {hasChild &&
          <div className={classes['passengerInfo-childDocument']}>
            <div className={classes['passengerInfo-row']}>
              <label className={classes['passengerInfo-label']}>
                <Input
                  className={classes['passengerInfo-input']}
                  placeholder='Фамилия'
                  defaultValue={defaultValues.childLastName}
                  {...register("childLastName", {
                    required: "Фамилия - обязательное поле",
                  })}
                  onChange={
                    getChangeHandlerWithValue("childLastName")
                  }
                />
                <div className={classes['passengerInfo-validationError-message']}>
                  {errors.childLastName && errors.childLastName.message}
                </div>
              </label>
              <label className={classes['passengerInfo-label']}>
                <Input
                  className={classes['passengerInfo-input']}
                  placeholder='Имя'
                  defaultValue={defaultValues.childName}
                  {...register("childName", {
                    required: "Имя - обязательное поле",
                    pattern: {
                      value: /[^0-9._%+-]/,
                      message: 'В поле можно вводить только буквы',
                    }
                  })}
                  onChange={
                    getChangeHandlerWithValue("childName")
                  }
                />
                <div className={classes['passengerInfo-validationError-message']}>
                  {errors.childName && errors.childName.message}
                </div>
              </label>
              <label className={classes['passengerInfo-label']}>
                <Input
                  className={classes['passengerInfo-input']}
                  placeholder='Отчество'
                  defaultValue={defaultValues.childMiddleName}
                  {...register("childMiddleName")}
                  onChange={
                    getChangeHandlerWithValue("childMiddleName")
                  }
                />
                <div className={classes['passengerInfo-validationError-message']}>
                  {errors.childMiddleName && errors.childMiddleName.message}
                </div>
              </label>
              <label className={classes['passengerInfo-label']}>
                <Select
                  className={`${classes['passengerInfo-select']} ${'passengerInfo-select'}`}
                  placeholder='Пол'
                  defaultValue={defaultValues.childGender}
                  {...register("childGender", {
                    required: "Пол - обязательное поле",
                  })}
                  onChange={
                    getChangeHandlerWithValue("childGender")
                  }
                >
                  <Select.Option value='m' label={genders.m}>{genders.m}</Select.Option>
                  <Select.Option value='f' label={genders.f}>{genders.f}</Select.Option>
                </Select>
                <div className={classes['passengerInfo-validationError-message']}>
                  {errors.childGender && errors.childGender.message}
                </div>
              </label>
            </div>
            <div className={classes['passengerInfo-document']}>
              {isWindowWidthMobile
              ?
                <div className={classes['passengerInfo-document-header']}>
                  <div className={classes['passengerInfo-document-header-title']}>Документ</div>
                  <div className={classes['passengerInfo-document-header-separator']}></div>
                </div>
              :
                <Divider orientation="left">
                  Документ
                </Divider>
              }
            </div>
            <div className={classes['passengerInfo-row']}>
              <label className={classes['passengerInfo-label']}>
                <Select
                  defaultValue='birthCertificate'
                  value='birthCertificate'
                  className={`${classes['passengerInfo-select']} ${'passengerInfo-select'}`}
                  placeholder='Документ'
                  {...register("childDocumentType")}
                  onChange={
                    getChangeHandlerWithValue("childDocumentType")
                  }
                >
                  <Select.Option value='birthCertificate' label={documentTypes.birthCertificate}>{documentTypes.birthCertificate}</Select.Option>
                </Select>
                <div className={classes['passengerInfo-validationError-message']}>
                  {errors.childDocumentType && errors.childDocumentType.message}
                </div>
              </label>
              <label className={classes['passengerInfo-label']}>
                <Input
                  className={classes['passengerInfo-input']}
                  placeholder='Номер документа'
                  defaultValue={defaultValues.childDocumentNumber}
                  {...register("childDocumentNumber", {
                    required: "Номер документа - обязательное поле",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Номер должен состоять только из цифр",
                    },
                    validate: {
                      toShort: (value) => (value.length === 10) || "Номер должен состоять из 10 цифр",
                      // onlyNums: (value) => value.match(/^[0-9]*$/) || "Номер должен состоять только из цифр",
                    }
                  })}
                  onChange={
                    getChangeHandlerWithValue("childDocumentNumber")
                  }
                />
                <div className={classes['passengerInfo-validationError-message']}>
                  {errors.childDocumentNumber && errors.childDocumentNumber.message}
                </div>
              </label>
              <label className={classes['passengerInfo-label']}>
                <Select
                  className={`${classes['passengerInfo-select']} ${'passengerInfo-select'}`}
                  placeholder='Гражданство'
                  defaultValue={defaultValues.childCitizenship}
                  {...register("childCitizenship", {
                    required: "Гражданство - обязательное поле",
                  })}
                  onChange={
                    getChangeHandlerWithValue("childCitizenship")
                  }
                  showSearch
                  label="true"
                  optionFilterProp='label'
                >
                  {countries.map((country) =>
                    <Select.Option value={country.code} label={country.name} key={country.code}>{country.name}</Select.Option>
                  )}
                </Select>
                <div className={classes['passengerInfo-validationError-message']}>
                  {errors.childCitizenship && errors.childCitizenship.message}
                </div>
              </label>
              <label className={classes['passengerInfo-label']}>
                <div className={classes['passengerInfo-validationError-message']}>
                  {errors.childBirthday && errors.childBirthday.message}
                </div>
                <DatePicker
                  // className={`${classes['passengerInfo-datePick']} ${'passengerInfo-datePick'}`}
                  className='passengerInfo-datePick'
                  popupClassName='passengerInfo-datePick-popup'
                  minDate={dayjs().add(-2, "years").add(+1, "days")}
                  maxDate={dayjs()}
                  format='DD.MM.YYYY'
                  defaultPickerValue={getValues('childBirthday') ? dayjs(getValues('childBirthday')) : dayjs().add(-1, "years")}
                  placement='topRight'
                  placeholder='Дата рождения'
                  // onCalendarChange={() => getChangeHandlerWithValue("birthday")}
                  // getPopupContainer={trigger => trigger.parentElement}
                  {...register("childBirthday", {
                    required: "Дата рождения - обязательное поле",
                  })}
                  onChange={(e) => getChangeHandlerWithValue("childBirthday")(e)}
                />
              </label>
            </div>
          </div> 
        }
      </form>
      {!isEditing && 
        <BaggageCounter baggageCount={baggageCount} setBaggageCount={setBaggageCount} passengerInfoIndex={index} changePassengersBaggage={changePassengersBaggage}/>
      }
    </div>
	)
})

export default PassengerInfo