import React, { useEffect, useState } from 'react'
import classes from './Ticket.module.css'
import NavBar from '../../components/NavBar/NavBar'
import Wrapper from '../../components/Wrapper/Wrapper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import H1 from '../../components/H1/H1'
import dayjs from 'dayjs'
import { getOrder, updateOrder } from '../../http/admin'
import { Button } from '../../components/Button/Button'
import { Input, notification, Select } from 'antd'
import moment from 'moment'
import { getCountries } from '../../http/dictionary'
import { setCountries } from '../../store/DictionaryStore'
import PassengerInfo from '../../components/PassengerInfo/PassengerInfo'
import { blue1, beige4 } from '../../utils/consts'
import Icons from '../../components/Icons/Icons'

const Ticket = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  let id = params.get('id')
  const allDictionaries = useSelector(state => state.dictionary._dictionaries)
  const countries = useSelector(state => state.dictionary._countries)
  const [order, setOrder] = useState(null)
  const [orderIsChanging, setOrderIsChanging] = useState(false)
  const [ticketIsChanging, setTicketIsChanging] = useState(false)
  const [passengersData, setPassengersData] = useState([])
  const [dataForCalculatingChanged, setDataForCalculatingChanged] = useState(false)
  const [newOrderData, setNewOrderData] = useState({
    email: null,
    phoneNumber: null,
    status: null,
  })
  const orderStatuses = allDictionaries.orderStatuses
  const orderItemStatuses = allDictionaries.orderItemStatuses
  const dispatch = useDispatch()

  const [messageApi, contextHolder] = notification.useNotification()
  const returnMessage = (type, title, content, className, style, duration) => {
    messageApi.open({
      // type: type,
      message: title,
      description: content,
      className: 'notification',
      style: style,
      duration: duration,
      placement: 'top',
      closeIcon:
        <Icons
          name='x'
          size='24px'
          stroke={blue1}
        />,
    })
  }

  const setPassengersSeat = (newPassenger) => setPassengersData({...newPassenger, baggage: newPassenger.baggageCount})

  const changePassengersBaggage = (index, newBaggage, seatNumber) => {
    let newPassengersData = passengersData
    console.log(newBaggage)
    newPassengersData = {...passengersData, baggage: newBaggage}
    setPassengersData(newPassengersData)
    console.log(passengersData)
  }

  const fetchOrder = async (id) => {
    try {
      let {data} = await getOrder(id)
      console.log('order', data)
      setOrder(data)
      setNewOrderData({...newOrderData, email: data.email, phoneNumber: data.phoneNumber, status: data.status})
    } catch (e) {
      console.log(e)
      returnMessage('error', e.response.status, e.response.data.message, '', '', 4)
    }
  }

  const fetchUpdateOrder = async () => {
    try {
      if (
        JSON.stringify({
          email: order.email,
          phoneNumber: order.phoneNumber,
          status: order.status,
        }) === 
        JSON.stringify({
          email: newOrderData.email,
          phoneNumber: newOrderData.phoneNumber,
          status: newOrderData.status,
        })
      ) {
        returnMessage('error', '', 'Данные не изменены', '', '', 10)
        console.log('Данные не изменены')
      }
      else if (newOrderData.email !== '' && newOrderData.phoneNumber !== '' && newOrderData.status !== '') {
        try {
          let {data} = await updateOrder(id, newOrderData.email, newOrderData.phoneNumber, newOrderData.status)
          console.log(data)
          setOrder(data)
          setOrderIsChanging(false)
        } catch (e) {
          returnMessage('error', '', 'Ошибка', '', '', 10)
          console.log(e)
        }
      }
      else {
        returnMessage('error', '', 'Поля не могут быть пустыми', '', '', 10)
        console.log('Поля не могут быть пустыми')
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchCountries = async () => {
    try {
      let {data} = await getCountries()
      dispatch(setCountries(data))
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchOrder(id)
    if (countries.length === 0) {
      fetchCountries()
    }
  }, [])

  return (
    <>
      {contextHolder}
      <NavBar/>
      <Wrapper>
        <div className={'previousPage'}>
          <div className={'previousPage-container'} onClick={() => navigate(-1)}>
            <Button
              className={`previousPage-button`}
            >
              <Icons
                name='arrow-left'
                size='24px'
                stroke={beige4}
                className={''} 
              />
            </Button>
            <div className={'previousPage-label'}>Назад</div>
          </div>
        </div>
        <H1>Заказ {order && order.number}</H1>
        {order &&
          <div className={classes['order']}>     
          {!orderIsChanging  
          ?        
            <div className={classes['order-data']}>
              <div className={classes['order-data-table']}>
                <div className={`${classes['order-data-table-item']}`}>
                  <div className={`${classes['order-data-table-item-title']}`}>Номер заказа</div>
                  <div className={`${classes['order-data-table-item-value']}`}>{order.number}</div>
                </div>
                <div className={`${classes['order-data-table-item']}`}>
                  <div className={`${classes['order-data-table-item-title']}`}>Пассажиры</div>
                  <div className={`${classes['order-data-table-item-value']}`}>{order.passengersCount}</div>
                </div>
                <div className={`${classes['order-data-table-item']}`}>
                  <div className={`${classes['order-data-table-item-title']}`}>Стоимость</div>
                  <div className={`${classes['order-data-table-item-value']}`}>{order.price}</div>
                </div>
                <div className={`${classes['order-data-table-item']}`}>
                  <div className={`${classes['order-data-table-item-title']}`}>email</div>
                  <div className={`${classes['order-data-table-item-value']}`}>{order.email ? order.email : '-'}</div>
                </div>
                <div className={`${classes['order-data-table-item']}`}>
                  <div className={`${classes['order-data-table-item-title']}`}>Номер телефона</div>
                  <div className={`${classes['order-data-table-item-value']}`}>{order.phoneNumber ? order.phoneNumber : '-'}</div>
                </div>
                <div className={`${classes['order-data-table-item']}`}>
                  <div className={`${classes['order-data-table-item-title']}`}>Статус</div>
                  <div className={`${classes['order-data-table-item-value']}`}>{orderStatuses[`${order.status}`]}</div>
                </div>
                <div className={`${classes['order-data-table-item']}`}>
                  <div className={`${classes['order-data-table-item-title']}`}>Дата создания</div>
                  <div className={`${classes['order-data-table-item-value']}`}>{dayjs(order.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                </div>
              </div>
              <div className={`${classes['order-data-controls']}`}>
                <Button
                  className={classes['order-data-editButton']}
                  onClick={() => setOrderIsChanging(true)}
                >
                  Изменить
                </Button>
              </div>
            </div>     
          :
            <div className={classes['order-data']}>
              <div className={classes['order-data-edit']}>
                <div className={classes['order-data-edit-label']}>
                  {orderIsChanging &&
                    <label className={classes['order-data-edit-label-title']}>e-mail</label>
                  }
                  <Input
                    className={`input`}
                    style={{width: '300px'}}
                    defaultValue={order.email}
                    placeholder={'e-mail'}
                    onChange={function(e) {
                      setNewOrderData({...newOrderData, email: e.target.value})
                    }}
                  />
                </div>
                <div className={classes['order-data-edit-label']}>
                  {orderIsChanging &&
                    <label className={classes['order-data-edit-label-title']}>Номер телефона</label>
                  }
                  <Input
                    className={`input`}
                    style={{width: '300px'}}
                    defaultValue={order.phoneNumber}
                    placeholder={'Номер телефона'}
                    onChange={function(e) {
                      setNewOrderData({...newOrderData, phoneNumber: e.target.value})
                    }}
                  />
                </div>
                <div className={classes['order-data-edit-label']}>
                  {orderIsChanging &&
                    <label className={classes['order-data-edit-label-title']}>Статус</label>
                  }
                  <Select
                    className={`select ${classes['order-select']}`}
                    style={{width: '300px'}}
                    defaultValue={order.status}
                    placeholder='Статус'
                    label="true"
                    optionFilterProp='label'
                    onChange={(value) => {
                      setNewOrderData({...newOrderData, status: value})
                    }}
                  >
                    {orderStatuses && Object.keys(orderStatuses).map((status, index) => 
                      <Select.Option value={status} label={Object.values(orderStatuses)[index]} key={Object.values(orderStatuses)[index]}>{Object.values(orderStatuses)[index]}</Select.Option>
                    )}
                  </Select>
                </div>
              </div>
              <div className={`${classes['order-data-controls']}`}>
                <Button
                  className={classes['order-data-editButton']}
                  onClick={() => {
                    fetchUpdateOrder()
                  }}
                >
                  Подтвердить
                </Button>
                <Button
                  className={classes['order-data-editButton']}
                  onClick={() => setOrderIsChanging(false)}
                >
                  Отмена
                </Button>
              </div>
            </div>
          }
            <div className={classes['order-passengers']}>
              {!ticketIsChanging && order !== null && order.items.map((passenger, index) => 
                <div className={`${classes['order-passengers-passengerInfo']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['order-passengers-passengerInfo__canceled']}`}`} key={passenger.id}>
                  <div className={`${classes['order-passengers-passengerInfo-header']}`}>
                    <div className={`${classes['order-passengers-passengerInfo-header-label']} ${classes['order-passengers-passengerInfo-header-passengerNumber']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['order-passengers-passengerInfo__canceled-header-label']} ${classes['order-passengers-passengerInfo__canceled-header-passengerNumber']}`}`}>Билет {index + 1}</div>
                    <div className={`${classes['order-passengers-passengerInfo-header-separator']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['order-passengers-passengerInfo__canceled-header-separator']}`}`}></div>
                    <div className={`${classes['order-passengers-passengerInfo-header-label']} ${classes['order-passengers-passengerInfo-header-seatNumber']} ${!['paid', 'new', 'pending_payment'].includes(passenger.status) && `${classes['order-passengers-passengerInfo__canceled-header-label']} ${classes['order-passengers-passengerInfo__canceled-header-seatNumber']}`}`}>Место {passenger.seatNumber}</div>
                  </div>
                  <div className={`${classes['order-passengers-passengerInfo-table']}`}>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Имя</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{passenger.document.firstName.toString().toUpperCase()}</div>
                    </div>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Фамилия</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{passenger.document.lastName.toString().toUpperCase()}</div>
                    </div>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Отчество</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{passenger.document.middleName || '-'}</div>
                    </div>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Пол</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{passenger.document.gender === 'm' ? 'Мужской' : passenger.document.gender === 'f' ? 'Женский' : '-'}</div>
                    </div>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Документ</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{passenger.document.documentType === 'passport' ? 'Паспорт' : passenger.document.documentType === 'birthCertificate' ? 'Свидетельство о рождении' : '-' }</div>
                    </div>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Номер</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{passenger.document.documentNumber}</div>
                    </div>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Гражданство</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{countries.length ? countries.find(country => country.code == passenger.document.countryCode).name : '-'}</div>
                    </div>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Дата рождения</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{moment(passenger.document.birthday).format('DD.MM.YYYY')}</div>
                    </div>
                    <div className={`${classes['order-passengers-passengerInfo-table-data-item']}`}>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-title']}`}>Багаж</div>
                      <div className={`${classes['order-passengers-passengerInfo-table-data-item-content']}`}>{passenger.baggageCount}</div>
                    </div>
                  </div>
                  <div className={classes['order-passengers-passengerInfo-footer']}>
                    {dayjs(order.createdAt).add(-2, 'years') < dayjs(passenger.document.birthday) &&                    
                      <Button
                        disabled
                      >
                        Детский
                      </Button>
                    }
                    <Button
                      disabled
                    >
                      {passenger.tariff.name} тариф
                    </Button>
                    <Button
                      disabled
                    >
                      {orderItemStatuses[`${passenger.status}`]}
                    </Button>
                    <Button
                      className={`${classes['order-passengers-passengerInfo-footer-button']} ${passenger.status != 'paid' && `${classes['order-passengers-passengerInfo__canceled-footer-button']}`}`}
                      onClick={() => {
                        setTicketIsChanging(true)
                        setPassengersData(passenger)
                        setPassengersSeat(passenger)
                      }}
                    >
                      Изменить
                    </Button>
                  </div>
                </div>
              )}
              {ticketIsChanging && 
                <div className={classes['order-passengers']}>
                  {order.items.map((passenger, index) => 
                    <PassengerInfo
                      passengerInfo={passenger}
                      key={passenger.id}
                      values={passenger.document}
                      baggage={passenger.baggageCount}
                      index={index}
                      changePassengersBaggage={changePassengersBaggage}
                      setDataForCalculatingChanged={setDataForCalculatingChanged}
                      dataForCalculatingChanged={dataForCalculatingChanged}
                      isEditing={true}
                      // setHasUnsavedChanges={setHasUnsavedChanges}
                      // returnMessage={returnMessage}
                      // isModalOrderCreatedOpen={isModalOrderCreatedOpen}
                    />
                  )}
                  <div className={classes['order-passengers-passengerInfo-footer']}>
                    <Button
                      className={`${classes['order-passengers-passengerInfo-footer-button__cancel']}`}
                      onClick={() => {
                        setTicketIsChanging(false)
                      }}
                    >
                      Отмена
                    </Button>
                    <div className={classes['order-passengers-passengerInfo-footer-controls']}>
                      <div className={classes['order-passengers-passengerInfo-footer-controls-item']}>
                        <label className={classes['order-passengers-passengerInfo-footer-controls-label']}>
                          <Input
                            className={classes['order-passengers-passengerInfo-footer-controls-input']}
                            placeholder='Пароль'
                          />
                        </label>
                        <Button
                          className={`${classes['order-passengers-passengerInfo-footer-button']}`}
                          onClick={() => {
                            
                          }}
                        >
                          Отправить код
                        </Button>
                      </div>
                      <div className={classes['order-passengers-passengerInfo-footer-controls-item']}>
                        <label className={classes['order-passengers-passengerInfo-footer-controls-label']}>
                          <Input
                            className={classes['order-passengers-passengerInfo-footer-controls-input']}
                            placeholder='Код подтверждения'
                          />
                        </label>
                        <Button
                          className={`${classes['order-passengers-passengerInfo-footer-button']}`}
                          onClick={() => {
                            
                          }}
                        >
                          Подтвердить
                        </Button>
                      </div>
                      <Button
                        className={`${classes['order-passengers-passengerInfo-footer-button']}`}
                        onClick={() => {
                          
                        }}
                      >
                        Изменить билет
                      </Button>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
        <div className='settings'>

        </div>
      </Wrapper>
    </>
  )
}

export default Ticket