export const getStorageWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key)
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	if (now.getTime() > new Date(item.expires)) {
		localStorage.removeItem(key)
		return null
	}
	return item.value
}