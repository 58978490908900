// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[6].use[2]!../../utils/fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_wrapper-container__emYRu {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
}

.Auth_auth__RbMpe {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  background: #E6EAFA;
  border-radius: 32px;
  padding: 40px;
  row-gap: 20px;
}
.Auth_auth-container__pbVLM {
  display: flex;
  justify-content: center;
}
.Auth_auth-form__F1cre {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Auth.module.css"],"names":[],"mappings":"AACA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,aAAa;AACf;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf","sourcesContent":["@import '../../utils/fonts.css';\n.wrapper-container {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  justify-content: center;\n}\n\n.auth {\n  display: flex;\n  flex-direction: column;\n  max-width: 500px;\n  width: 100%;\n  background: #E6EAFA;\n  border-radius: 32px;\n  padding: 40px;\n  row-gap: 20px;\n}\n.auth-container {\n  display: flex;\n  justify-content: center;\n}\n.auth-form {\n  display: flex;\n  flex-direction: column;\n  row-gap: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper-container": `Auth_wrapper-container__emYRu`,
	"auth": `Auth_auth__RbMpe`,
	"auth-container": `Auth_auth-container__pbVLM`,
	"auth-form": `Auth_auth-form__F1cre`
};
export default ___CSS_LOADER_EXPORT___;
