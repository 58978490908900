import { createSlice } from "@reduxjs/toolkit";

const DictionaryStore = createSlice({
  name: 'dictionary',
  initialState: {
    _dictionaries: {},
    _countries: [],
  },
  reducers: {
    setDictionaries(state, action) {
      state._dictionaries = action.payload;
      console.log(state._dictionaries)
    },
    setCountries(state, action) {
      state._countries = action.payload;
      console.log(state._countries)
    },
  }
})

export const { setDictionaries, setCountries } = DictionaryStore.actions;

export default DictionaryStore.reducer;