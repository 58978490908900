import { configureStore } from '@reduxjs/toolkit'
import UserStore from './UserStore'
import DictionaryStore from './DictionaryStore'
import TripStore from './TripStore'
import TicketStore from './TicketStore'

export default configureStore({
    reducer: {
        user: UserStore,
        dictionary: DictionaryStore,
        trip: TripStore,
        ticket: TicketStore,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoreActions: 'setSelectedDate',
            ignoreState: 'trip._selectedDate',
        }
    })
})