import React from 'react'
import classes from './H1.module.css'

const H1 = ({...params}) => {
  return (
    <h1 className={`${classes['h1-desktop']} ${params.className}`}>
        {params.children}
    </h1>
  )
}

export default H1