// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wrapper_container__PDMR0 {
  display: flex;
  justify-content: center;
  width: 100%;
}

.Wrapper_content__mm6g2 {
  position: relative;
  width: 100%;
  max-width: 1572px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding: 0px 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Wrapper/Wrapper.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.content {\n  position: relative;\n  width: 100%;\n  max-width: 1572px;\n  display: flex;\n  flex-direction: column;\n  gap: 22px;\n  padding: 0px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Wrapper_container__PDMR0`,
	"content": `Wrapper_content__mm6g2`
};
export default ___CSS_LOADER_EXPORT___;
