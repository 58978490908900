import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const TripStore = createSlice({
  name: 'trip',
  initialState: {
    _trip: {},
    _cancelTripStatus: null,
    _fromStopList: [],
    _toStopList: [],
    _selectedFromStop: null,
    _selectedToStop: null,
    _selectedDate: dayjs(),
  },
  reducers: {
    setTrip(state, action) {
      state._trip = action.payload;
    },
    setCancelTripStatus(state, action) {
      state._cancelTripStatus = action.payload;
    },
    setFromStopList(state, action) {
      state._fromStopList = action.payload;
    },
    setToStopList(state, action) {
      state._toStopList = action.payload;
    },
    setSelectedFromStop(state, action) {
      state._selectedFromStop = action.payload;
    },
    setSelectedToStop(state, action) {
      state._selectedToStop = action.payload;
    },
    setSelectedDate(state, action) {
      state._selectedDate = action.payload;
    },
  }
})

export const { setTrip, setCancelTripStatus, setFromStopList, setToStopList, setSelectedFromStop, setSelectedToStop, setSelectedDate } = TripStore.actions;

export default TripStore.reducer;